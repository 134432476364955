import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from '../components/app/notifier/notifier.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private notifierService: NotifierService
  ) { }

  public handleError(err: Error | HttpErrorResponse) {
    if (err instanceof HttpErrorResponse) {
      switch (err.status) {
        case 400:
          this.notifierService.notify('error', 'Dados Inválidos', this.getErrorMessage(err));
          break;
        case 403:
          this.notifierService.notify('error', 'Erro 403', 'Acesso não autorizado');
          break;
        case 500:
          this.notifierService.notify('error', 'Ocorreu um problema', this.getErrorMessage(err));
          break;
        default:
          this.notifierService.notify('error', 'Erro', `Erro Genérico - ${err.message}`);
      }
    }
  }

  private getErrorMessage(err: any): string {
    try {
      return err.error.erros.shift();
    } catch (error) {
      return err.message;
    }
  }

}
