import { Component, OnInit, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from './header.service';
import { StorageService } from 'src/app/core/utils/storage.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ClienteService } from 'src/app/core/services/cliente/cliente.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  client: any;
  ip: string;
  date = new Date();
  isAuthenticated: boolean;

  constructor(
    private router: Router,
    private storage: StorageService,
    private headerService: HeaderService,
    private authService: AuthService,
    private clienteService: ClienteService
  ) { }

  ngOnInit(): void {
    this.getIdCliente();
    this.getIPAddress();
    this.getAuth();
  }

  private getAuth(): void {
    this.authService.authenticated.subscribe(data => {
      this.isAuthenticated = data;
    });
  }

  private getIdCliente(): any {
    const subDomain = environment.frontGissV2.split('/')[2].split('.')[0];
    this.headerService.getIdCliente(subDomain).subscribe(data => {
      this.storage.setItem('idCliente', data.conteudo.idCliente);
      this.clienteService.carregouCliente(true);
      this.getCliente();
    });
  }

  private getCliente(): void {
    const idCliente = this.storage.getItem('idCliente');
    this.headerService.getCliente(idCliente).subscribe(data => this.client = data.conteudo);
  }

  private getIPAddress(): void {
    this.headerService.getIPAddress().subscribe(
      data => this.ip = data.ip,
      () => this.ip = '0.0.0.0');
  }

  public logoff(): void {
    this.storage.removeItem('token');
    this.authService.emitAuth(false);
    this.router.navigate(['/']);
  }

  public redirectServidor(): void {
    this.router.navigate(['/praca-atendimento']);
  }

}
