<section class="box-banner">
  <carousel [isAnimated]="true">
    <slide *ngFor="let number of [1]">
      <img class="img-fluid d-none d-sm-block" src="../../../assets/img/banner-gissonline-03.png" alt="banner app gissonline">
      <img class="img-fluid d-block d-sm-none" src="../../../assets/img/banner-gissonline-03.png" alt="banner app gissonline 2">
      <!-- <div class="row banner">
        <div class="col-md-12 text">
          <p>
            <span class="featured">Baixe seu aplicativo <br> GissOnline!</span>
            Disponível para Android e IOS.
          </p>
        </div>
        <div class="stores">
          <img class="img" src="../../../../assets/img/app-store.png" alt="Apple Store">
          <img class="img" src="../../../../assets/img/play-store.png" alt="Google Store">
        </div>
      </div> -->
    </slide>
  </carousel>

  <!--p class="acesso-sistema" *ngIf="!isAuthenticated"> -->
  <p class="acesso-sistema">
    <span>Já fez seu cadastro e vinculou sua(s) empresa(s) </span>
    <a class="link-acesso" href="{{ frontGissV2 }}">clique aqui</a>
    <span>. Caso ainda não tenha realizado, selecione uma das opção abaixo:</span>
  </p>
  <!-- <h2 [style.color]="titleColor">Veja o desempenho da cidade</h2> -->
  <hr>
<!--
  <div class="arrow">
    <div aria-label="arrow" class="icon" [inlineSVG]="'/assets/icons/down-arrow.svg'" [style.fill]="titleColor">
    </div>
  </div>

  <div class="row desempenho" *ngIf="indicadores">
    <div class="col-6 col-md-3 text-center">
      <span class="number">{{ indicadores.notasEmitidas | number }}</span>
      <span class="title">NFS-E<br>Válidas</span>
    </div>
    <div class="col-6 col-md-3 text-center">
      <span class="number">{{ indicadores.guiasGeradas | number }}</span>
      <span class="title">Guias<br>Geradas</span>
    </div>
    <div class="col-6 col-md-3 text-center">
      <span class="number">{{ indicadores.empresasAtivas | number }}</span>
      <span class="title">Empresas<br>Ativas</span>
    </div>
    <div class="col-6 col-md-3 text-center">
      <span class="number">{{ indicadores.valorImposto | currency: 'BRL': 'symbol' : '1.0-0' }}</span>
      <span class="title">Valor<br>Imposto</span>
    </div>
  </div>
-->
  <div class="row" *ngIf="!indicadores">
    <div class="col-12 d-flex justify-content-center my-5">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>

</section>
